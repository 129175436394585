.footer {
  padding: 10px 30px 0;
  background-color: rgb(64, 64, 64);
  background-image: url(https://bizweb.dktcdn.net/100/360/494/themes/801562/assets/bg-contrinhdathicon.png?1678676184068);
  background-repeat-y: no-repeat;
  background-position: center;
  color: antiquewhite;
  .copyright {
    text-align: center;
    margin: 10px;
    padding: 10px;
    background-image: linear-gradient(
      to right,
      rgb(95, 190, 116),
      rgb(133, 60, 235)
    );
  }
}
