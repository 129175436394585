.header {
  padding: 20px;
  font-size: 16px;
  margin-bottom: 30px;
  background-color: #663090;
  color: white;
  .headerLine {
    background-color: #8cc540;
    height: 20px;
    margin: -20px -20px 20px -20px;
  }
  .logo {
    text-align: right;
    img {
      width: 300px;
      height: auto;
    }
  }
  .title {
    text-align: center;
    font-size: 5rem;
    margin-top: -3rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .headerText {
    margin-top: 30px;
  }
  .headerCol {
    h5, ul {
      border-right: 2px solid #8cc540;
      margin-bottom: 0;
    }
    h5 {
      margin-left: 15px;
      text-transform: uppercase;
    }
  }
  .headerLastCol {
    h5 {
      margin-left: 15px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}
