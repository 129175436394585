.lebruderNews {
  margin: 20px;
  .article {
    margin: 10px 5px;
    border: 1px solid green;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    .title {
      background-color: #f0e4ff;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4; /* start showing ellipsis when 3rd line is reached */
      white-space: pre-wrap; /* let the text wrap preserving spaces */
      a {
        text-decoration: none;
      }
    }
    .detail {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5; /* start showing ellipsis when 3rd line is reached */
      white-space: pre-wrap; /* let the text wrap preserving spaces */
    }
    .imageSection {
      text-align: center;
      img {
        vertical-align: middle;
        width: 235px;
        height: auto;
      }
    }
  }
}
