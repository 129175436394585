.interiorProduct {
    color: #1a1735;
    min-height: 310px;
    background-color: white;
    .sectionTitle {
      padding: 10px 0;
      background-color: rgb(255, 255, 255);
      h3 {
        text-align: center;
      }
    }
  }