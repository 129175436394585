.product {
  background-color: rgb(227, 246, 240);
  margin: 0 10px;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  height: 410px;
  .productImg {
    text-align: center;
    img {
      width: 100%;
      height: 200px;
      text-align: center;
    }
  }
  .title {
    margin: 10px 0 5px 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0em;
  }
  .shortDescription {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.46429em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }
  .buttonGroup {
    width: 250px;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin-bottom: 10px;
    a {
      text-decoration: none;
    }
  }
}
