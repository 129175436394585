.homepage {
  .scrollBanner {
    height: 500px;
    img {
      width: 100%;
      height: 500px;
    }
  }
  .highlight {
    margin: 0 20px;
    height: 330px;
    margin-top: -45px;    z-index: 1;
    position: sticky;

    .item {
      background-color: rgb(179, 196, 190);
      height: 325px;
      padding: 10px;
      border-radius: 4px;
      a {
        text-decoration: none;
      }
      img {
        width: 100%;
        height: 200px;
        text-align: center;
      }
    }
  }
  .selfIntro {
    margin-top: 20px;
    border-radius: 4px;
    padding: 20px;
    background-color: #d8c6c6;
    color: #1a1735;
    height: 310px;
    .sectionTitle {
      padding: 5px 0px;
      h3 {
        text-align: center;
      }
    }
  }
  .product {
    color: #1a1735;
    min-height: 310px;
    .sectionTitle {
      padding: 5px 0px;
      background-color: rgb(255, 255, 255);
      h3 {
        text-align: center;
      }
      
    }
    .article {
      border-radius: 4px;
      margin: 5px 5px;
      .productArticle {
        background-color: #cbcbff;
        min-height: 250px;
        margin: 0 10px;
        .homeArticle {
          background-color: ghostwhite;
          .image {img {
            width: 100% !important;
          }}
        }
        
      }
    }
  }
}
