.product {
  .scrollBanner {
    height: 506px;
    img {
      width: 100%;
      height: 500px;
    }
  }
  .productIntro {
    margin: 20px;
    .introImg {
        text-align: right;
      img {
        width: auto;
        height: 150px;
      }
    }
    .sectionTitle {
        background-color: gray;
    }
  }
  .product {
    background-color: #a9a9a9;
    color: #1a1735;
    min-height: 310px;

    .sectionTitle {
      padding: 5px 0px;
      background-color: rgb(255, 255, 255);
      h3 {
        text-align: center;
      }
    }
    .article {
      border-radius: 4px;
      margin: 5px 5px;
      .productArticle {
        background-color: #cbcbff;
        min-height: 250px;
        margin: 0 10px;
      }
    }
  }
  .news {
    margin-top: 10px;
    color: #1a1735;
    min-height: 310px;
    background-color: white;
    .article {
      border-radius: 4px;
      background-color: #cbcbff;
      min-height: 250px;
      margin: 0 10px;
      .elArticle {
        border-radius: 4px;
        margin: 5px 5px;
        background-color: #8bde99;
      }
    }
  }
}
