.homeAricle {
  border: 1px solid blanchedalmond;
  border-radius: 4px;
  background-color: aliceblue;
  padding: 20px;
  .image {
    img {
      width: 100%;
    }
  }
  .title {
    margin-top: 10px;
  }
}
