.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  font-size: 18px;
  a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 1rem;
    display: flex;
    li {
      background-color: #555;
    }
    li.active {
      background-color: #333;
    }
    li.hover {
      background-color: #777;
    }
  }
}
